// createWebHashHistory 是hash模式就是访问链接带有#
// createWebHistory  是history模式
import { createRouter, createWebHashHistory } from 'vue-router'

// 引入文件，动态路由
const Login = () => import("@/views/login/index.vue");
const Layout = () => import("@/components/layout/index.vue");
const Home = () => import("@/views/home/index.vue");
const SkuIn = () => import("@/views/sku/in.vue");
const SkuInRecord = () => import("@/views/sku/in-record.vue");
const SkuInCurrent = () => import("@/views/sku/in-current.vue");
const SkuInCreate = () => import("@/views/sku/in-create.vue");
const SkuInDetailCreate = () => import("@/views/sku/in-detail-create.vue");
const SkuOut = () => import("@/views/sku/out.vue");
const SkuOutRecord = () => import("@/views/sku/out-record.vue");
const SkuOutCreate = () => import("@/views/sku/out-create.vue");
const SkuOutDetailCreate = () => import("@/views/sku/out-detail-create.vue");
const SkuIndex = ()=>import("@/views/sku/index.vue")
const SkuCreate = ()=>import("@/views/sku/create.vue")
const routes = [
    {
        path: "/",
        redirect: "/login"
    },
    {
        // 登录路由
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        // 登入后主页
        path: "/layout",
        name: "layout",
        component: Layout,
        redirect: "/home",
        children: [
            {
                path: "/home",
                name: "home",
                component: Home,
            },
            // {
            //     path: "/scan",
            //     name: "scan",
            //     component: Scan
            // },
            // {
            //     path: "/air-condition",
            //     name: "air_condition",
            //     component: AirCondition
            // },
        ]
    },
    {
        path: "/sku-in",
        name: "skuIn",
        component: SkuIn
    },
    {
        path: "/sku-in-record",
        name: "skuInRecord",
        component: SkuInRecord
    },
    {
        path: "/sku-index",
        name: "skuIndex",
        component: SkuIndex
    },
    {
        path: "/sku-create",
        name: "skuCreate",
        component: SkuCreate
    },
    {
        path: "/sku-in-current",
        name: "skuInCurrent",
        component: SkuInCurrent
    },
    {
        path: "/sku-in-create",
        name: "skuInCreate",
        component: SkuInCreate
    },
    {
        path: "/sku-in-detail-create",
        name: "skuInDetailCreate",
        component: SkuInDetailCreate
    },
    {
        path: "/sku-out",
        name: "skuOut",
        component: SkuOut
    },
    {
        path: "/sku-out-record",
        name: "skuOutRecord",
        component: SkuOutRecord
    },
    {
        path: "/sku-out-create",
        name: "skuOutCreate",
        component: SkuOutCreate
    },
    {
        path: "/sku-out-detail-create",
        name: "skuOutDetailCreate",
        component: SkuOutDetailCreate
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
