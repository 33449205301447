<template>
  <div id="app">
    <router-view />
    <!-- <van-tabbar v-if="showBar" v-model="active" route style="background: #ffffff">
      <van-tabbar-item replace to="/index" icon="home-o">HOME</van-tabbar-item>
      <van-tabbar-item replace to="/message" icon="chat-o" :badge="message_num">3D SCAN</van-tabbar-item>
      <van-tabbar-item replace to="/my" icon="user-o">空调控制</van-tabbar-item>
    </van-tabbar> -->
  </div>
</template>

<script>
// import MyWs from "./utils/ws";
const showBarPath = ["/index", "/message", "/my"];
export default {
  name: "App",
  data() {
    return {
      active: 1,
      message_num: "",
      showBar: false,
    };
  },
  created() {
    // this.getUrl();
    this.handleStart();
  },
  watch: {
    // $route(to) {
    //   this.showBar = showBarPath.indexOf(to.path) > -1;
    // },
  },
  methods: {
    // getUrl() {
    //   this.showBar = showBarPath.indexOf(this.$route.path) > -1;
    // },
    // handleStart() {
    //   if (localStorage.getItem("za_token")) {
    //     let Ws = new MyWs("wss://erp-brand.zhian-design.com:1238");
    //     Ws.subscribe("chat", handleChat);
    //   }
    // },
    // 获取websocket推送信息
    handleStart() {
      if (localStorage.getItem("za_token")) {
        // const Ws = new MyWs("ws://112.124.6.216:1238");
        // const handleChat = (data) => {
        //   if (data.message_type === "led_info") {
        //     this.$store.commit("webMsg/ADD_LED_INFO", data.content);
        //   }
        // };
        // Ws.subscribe("chat", handleChat);
      }
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}
body {
  margin: 0;
}
</style>
